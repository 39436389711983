//
// Note: this file should never be import directly. Use a getter via getters.js
//

import find from 'lodash/find'
import merge from 'lodash/merge'
import isNil from 'lodash/isNil'

const Env = {
  COMMON: {
    Logger: {
      SENTRY_URL: 'https://14c6c1620b0a40d8afe02317593f47b3@sentry.io/226787',
      DISPLAY_REPORT_DIALOG: true,
    },

    SHOULD_USE_APP_CONFIG: false,

    IS_BO: true,
    IS_QA: false,

    IS_LOCAL: false,
    IS_DEV: false,
    IS_PREPROD: false,
    IS_PROD: false,
  },

  LOCAL: {
    ENV: 'DEV',

    BASE_URL: /localhost/,
    HOSTNAME: 'localhost',
    API_URL: 'http://localhost:8080/rest',
    // API_DOC_URL : will be the API_URL with the apiVersion
    API_CONSUMER: 'Basic NTY0NDIzOTAwNjMzNDk3NjpBT29VeGs5N0JFdTV5Zw==',

    IS_LOCAL: true,
    IS_DEV: true,
  },

  PREPROD: {
    ENV: 'PREPROD',

    HOSTNAME: 'bo.preprod.paymytable.com',
    BASE_URL: /bo.preprod.paymytable.com/,
    API_URL: 'https://paymytable-eu-preprod.ew.r.appspot.com/rest',
    // API_DOC_URL : will be the API_URL with the apiVersion
    API_CONSUMER: 'Basic NTY0NDIzOTAwNjMzNDk3NjpBT29VeGs5N0JFdTV5Zw==',

    IS_PREPROD: true,
  },

  PRODUCTION: {
    ENV: 'PROD',

    HOSTNAME: 'bo.paymytable.com',
    BASE_URL: /bo.paymytable.com/,
    API_URL: 'https://bo-dot-paymytable-eu.ew.r.appspot.com/rest',
    API_DOC_URL: 'https://api.paymytable.com/rest',
    API_CONSUMER: 'Basic NjMwMzAzMDQ1NDUxNzc2MDpJS3dGSUFQYVhoekI=',

    IS_PROD: true,
  },
}

let currentEnv = Env.LOCAL
const hostname = window.location.hostname

const predicate = config => (!isNil(config.BASE_URL) ? hostname.match(config.BASE_URL) : null)

currentEnv = find(Env, predicate)

// currentEnv.API_URL = 'https://web-mobile-payment-dot-paymytabledev.appspot.com/rest'

if (process.env.__DEV__) {
  currentEnv = Env.LOCAL
}

console.log('[CONFIGURATION] ', currentEnv.API_URL, currentEnv.HOSTNAME)

export default merge({}, Env.COMMON, currentEnv)
